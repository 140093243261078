<template>

  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">环保问题管理</a></li>
            <li class="breadcrumb-item active" id="title3">添加环保问题</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">添加环保问题</h4>
      </div>
    </div>
     <el-form :model="formData" label-width="20%" ref="formDataRef" :rules="addrules"  align="left">
<el-form-item label="问题标题" prop="etitle">
<el-input v-model="formData.etitle" placeholder="问题标题"  style="width:50%;" ></el-input>
</el-form-item>
<el-form-item prop="eimage" label="问题图片"  min-width="20%">
<el-input  v-model="formData.eimage" placeholder="问题图片"  readonly="true" style="width:50%;" ></el-input>
<el-button type="primary" size="small" @click="showUpload">上传</el-button>
</el-form-item>
<el-form-item label="问题内容" prop="econtent">
<WangEditor  ref="wangEditorRef" v-model="formData.econtent" :config="editorConfig"   :isClear="isClear" @change="editorChange"></WangEditor>
</el-form-item>
<el-form-item>
<el-button type="primary" size="small" @click="save" :loading="btnLoading" icon="el-icon-upload">提 交</el-button>
<el-button type="info" size="small" @click="goBack" icon="el-icon-back">返 回</el-button>
</el-form-item>
</el-form>
     <el-dialog
        v-model="uploadVisible"
        title="附件上传"
        custom-class="el-dialog-widthSmall"
        @close="closeDialog"
      >
        <div>
          <b>请选择文件（png,jpg格式）进行上传！&emsp;</b>
        </div>
        <el-upload
          action="http://localhost:8088/gouwu/api/common/uploadFile"
          style="
            margin: auto;
            margin-top: 10px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
          "
          drag
          :limit="1"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :auto-upload="false"
          name="file"
          :on-change="fileListChange"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将数据文件拖到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip">
            <div
              style="display: inline; color: #d70000; font-size: 14px"
              class="uploadFileWarning"
              id="uploadFileWarning"
            ></div>
          </div>
        </el-upload>
        <span class="dialog-footer">
          <el-button @click="hideUpload">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">提 交</el-button>
        </span>
      </el-dialog>


  </div>

</template>
<script>
import request, { base } from "../../../utils/http";
import WangEditor from "../../components/WangEditor";
export default {
  name: 'EnvironmentAdd',
  components: {
    WangEditor,
  },  
    data() {
      return {   
        uploadVisible: false, 
        btnLoading: false, //保存按钮加载状态     
        formData: {}, //表单数据           
        addrules: {
          etitle: [{ required: true, message: '请输入问题标题', trigger: 'blur' },
],          eimage: [{ required: true, message: '请输入问题图片', trigger: 'blur' },
],        },

      };
    },
    mounted() {
    
    },

 
    methods: {    
   // 添加
    save() {       
         this.$refs["formDataRef"].validate((valid) => { //验证表单
           if (valid) {
             let url = base + "/environment/add";
             this.btnLoading = true;
             request.post(url, this.formData).then((res) => { //发送请求         
               if (res.code == 200) {
                 this.$message({
                   message: "操作成功",
                   type: "success",
                   offset: 320,
                 });              
                this.$router.push({
                path: "/EnvironmentManage",
                });
               } else {
                 this.$message({
                   message: res.msg,
                   type: "error",
                   offset: 320,
                 });
               }
               this.btnLoading=false;
             });
           }        
           
         });
    },
    
       // 返回
        goBack() {
          this.$router.push({
            path: "/EnvironmentManage",
          });
        },       
              
          
            //显示上传框
    showUpload() {
      this.uploadVisible = true;
    },

    //隐藏上传框
    hideUpload() {
      this.uploadVisible = false;
    },
    //上传
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message({
        duration: 1000,
        message: "只能上传一个文件",
        type: "error",
        offset: 320,
      });
    },
    // 判断上传文件后缀
    fileListChange(file, fileList) {
      let extendFileName = "png,jpg";
      let extendFileNames = extendFileName.split(",");
      let regExpRules = [];
      for (let i = 0; i < extendFileNames.length; i++) {
        regExpRules.push(
          new RegExp("(.*).(" + extendFileNames[i] + ")$", "gim")
        );
      }
      let fileNames = [];
      let files = [];
      let that = this;
      fileList.forEach(function (key, val) {
        let ret = false;
        for (let i = 0; i < regExpRules.length; i++) {
          ret = ret || regExpRules[i].test(key["name"]);
        }
        if (!ret) {
          console.log(key["name"] + ":" + ret);
          that.$message({
            duration: 1000,
            message: "上传的文件后缀必须为" + extendFileName + "格式！",
            type: "error",
            offset: 320,
          });
          return false;
        }
        if (fileNames.indexOf(key["name"]) != -1) {
          that.$message({
            duration: 1000,
            message: "上传的文件重复！",
            type: "error",
            offset: 320,
          });
          return false;
        }
        //只能上传一个文件，用最后上传的覆盖
        if (!that.multiFiles) {
          files = [];
          fileNames = [];
        }
        files.push(key);
        fileNames.push(key["name"]);
        if (fileNames !== "") {
          // $('#uploadMad .el-upload-dragger').css('border-color', '#409eff');
        }
        //$(".uploadFileWarning").text("");
      });
      this.files = fileNames;
      this.fileList = files;
    },
    /**
     * 确认按钮
     */
    handleConfirm() {
      let filePath = this.fileList;
      if (filePath.length === 0) {
        this.$message({
          duration: 1000,
          message: "请选择文件！",
          type: "error",
          offset: 320,
        });
        return false;
      }
      let formData = new FormData();
      this.fileList.forEach((file) => {
        formData.append("file", file.raw, file.raw.name);
      });
      let url = base + "/common/uploadFile";
      console.log("url=" + url);
      request.post(url, formData).then((res) => {
        console.log(res);
        let furl = res.resdata.filePath;
        this.formData.eimage = furl;  // 上传文件的路径
        this.hideUpload();
        console.log(res);
      });
    },

   
            // 富文本编辑器
    editorChange(val) {
      this.formData.econtent = val;
    },
   
      },
}

</script>
<style scoped>
</style>
 

